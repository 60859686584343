// main colors
$color-yellow: #EDBF00
$color-red: #eea298

$color-white: #FFFFFF
$color-black: #000000
$color-grey: #333333
$color-grey-2: #202020
$color-grey-3: #545454
$color-text: #E6E6E6
$color-heading: $color-white

$color-success: #155724
$color-success-background: #D4EDDA
$color-success-border: #C3E6CB
$color-error: #721c24
$color-error-background: #F8D7DA
$color-error-border: #F5C6CB

$break22: 22rem
$break30: 30rem
$break37: 37rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem
$break125: 125rem

$contentWidth-small: 40.625rem
$contentWidth-normal: 68.75rem
$contentWidth-wide: 84.375rem

$header-height: 7.0625rem

$linkList-borderRadius: 1.875rem
