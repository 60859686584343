._1I_qj{--Collapsible-transition-duration: 0.3s;--Collapsible-transition-timing-function: ease;--Collapsible-content-height: auto;display:flex;transition-property:height,opacity,visibility;transition-timing-function:var(--Collapsible-transition-timing-function);transition-duration:var(--Collapsible-transition-duration)}@media(prefers-reduced-motion: reduce){._1I_qj{--Collapsible-transition-duration: 1ms;--Collapsible-transition-timing-function: step-end}}._2L9NA{align-items:flex-start}._1HWL6{align-items:flex-end}._3OHvW,._3U8bo,._3FGzA{overflow:hidden;visibility:hidden;opacity:0;height:0}._RKZOX{overflow:hidden;height:var(--Collapsible-content-height)}._2mqKm{height:auto}._fzKDc{height:var(--Collapsible-content-height)}._2jwZj{width:100%}
:root{--ProgressLoadingIndicator-color: #EDBF00;--common-edge-gap: .9375rem;--common-content-width-small: 40.625rem;--common-content-width-normal: 68.75rem;--common-content-width-wide: 84.375rem;--content-fontSize: 1.125em;--content-gap-default: 5rem;--content-gap-small: 1.875rem}@media(min-width: 48rem){:root{--content-fontSize: 1.25em;--content-gap-default: 7rem}}@media(min-width: 40rem){:root{--common-edge-gap: 1.25rem}}html{height:-webkit-fill-available}body{margin:0;font-family:var(--font-body);background-color:#000;color:#e6e6e6;width:100%}#__next{display:contents}*,*::before,*::after{box-sizing:border-box}button{cursor:pointer}h1,h2,h3,h4,h5{color:#fff}input{font-family:sans-serif}
@font-face {
font-family: '__ClashGrotesk_11e0f1';
src: url(/_next/static/media/25113e1936ded3ba.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__ClashGrotesk_11e0f1';
src: url(/_next/static/media/fe501771e48c7d75.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ClashGrotesk_11e0f1';
src: url(/_next/static/media/63b4fc36549c6773.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__ClashGrotesk_11e0f1';
src: url(/_next/static/media/402d914664b44ad1.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__ClashGrotesk_Fallback_11e0f1';src: local("Arial");ascent-override: 92.12%;descent-override: 25.88%;line-gap-override: 9.32%;size-adjust: 96.61%
}.__className_11e0f1 {font-family: '__ClashGrotesk_11e0f1', '__ClashGrotesk_Fallback_11e0f1'
}

