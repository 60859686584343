@use 'common'
@import 'collapsible-react-component/dist/index.css'

:root
	--ProgressLoadingIndicator-color: #{common.$color-yellow}
	--common-edge-gap: .9375rem
	--common-content-width-small: #{common.$contentWidth-small}
	--common-content-width-normal: #{common.$contentWidth-normal}
	--common-content-width-wide: #{common.$contentWidth-wide}

	--content-fontSize: 1.125em

	--content-gap-default: 5rem
	--content-gap-small: 1.875rem

	@media (min-width: common.$break48)
		--content-fontSize: 1.25em
		--content-gap-default: 7rem

	@media (min-width: 40rem)
		--common-edge-gap: 1.25rem

html
	height: -webkit-fill-available

body
	margin: 0
	font-family: var(--font-body)
	background-color: common.$color-black
	color: common.$color-text
	width: 100%

	// @TODO: light & dark theme ?

#__next
	display: contents

*, *::before, *::after
	box-sizing: border-box

button
	cursor: pointer

h1, h2, h3, h4, h5
	color: common.$color-heading

input
	font-family: sans-serif
